import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, TableLv2 } from '@/components';
import TitleFilter, { menuKeyToDevType } from '../component/title-filter';
import './index.less';
import { defaultAxios } from '@/utils';
import { message } from 'antd';
import {
    trans2WDotTwo,
    trans2DotTwo,
} from '../../dashboard/composite-live/component/achievement-situation';
import _ from 'lodash';

const AchievementCompletion: React.FC = () => {
    const [achievementCompletionData, setAchievementCompletionData] = useState<
        any
    >([]);
    const [
        achievementCompletionLoading,
        setAchievementCompletionLoading,
    ] = useState(false);
    const [acMenuSelect, setACMenuSelect] = useState<string>('');
    const [acYearSelect, setAcYearSelect] = useState<string>('');
    const renderMoney2W = (v: number) => {
        return v ? `${trans2WDotTwo(v)}w` : '-';
    };
    const renderPercent = (v: number) => {
        return v ? `${trans2DotTwo(v)}%` : '-';
    };
    useEffect(() => {
        fetchAchievementCompletionData();
    }, [acMenuSelect, acYearSelect]);
    const tableColumns: any = [
        {
            title: '季度',
            children: [
                {
                    render: renderMoney2W,
                    title: 'Q1目标',
                    dataIndex: 'income_target_quarter1',
                },
                {
                    render: renderMoney2W,
                    title: 'Q1业绩',
                    dataIndex: 'income_money_quarter1',
                },
                {
                    render: renderPercent,
                    title: 'Q1完成率',
                    dataIndex: 'completion_rate_quarter1',
                },
                {
                    render: renderMoney2W,
                    title: 'Q2目标',
                    dataIndex: 'income_target_quarter2',
                },
                {
                    render: renderMoney2W,
                    title: 'Q2业绩',
                    dataIndex: 'income_money_quarter2',
                },
                {
                    render: renderPercent,
                    title: 'Q2完成率',
                    dataIndex: 'completion_rate_quarter2',
                },
                {
                    render: renderMoney2W,
                    title: 'Q3目标',
                    dataIndex: 'income_target_quarter3',
                },
                {
                    render: renderMoney2W,
                    title: 'Q3业绩',
                    dataIndex: 'income_money_quarter3',
                },
                {
                    render: renderPercent,
                    title: 'Q3完成率',
                    dataIndex: 'completion_rate_quarter3',
                },
                {
                    render: renderMoney2W,
                    title: 'Q4目标',
                    dataIndex: 'income_target_quarter4',
                },
                {
                    render: renderMoney2W,
                    title: 'Q4业绩',
                    dataIndex: 'income_money_quarter4',
                },
                {
                    render: renderPercent,
                    title: 'Q4完成率',
                    dataIndex: 'completion_rate_quarter4',
                },
            ],
        },
        {
            title: '月份',
            children: [
                {
                    render: renderMoney2W,
                    title: '1月目标',
                    dataIndex: 'income_target_month1',
                },
                {
                    render: renderMoney2W,
                    title: '1月业绩',
                    dataIndex: 'income_money_month1',
                },
                {
                    render: renderPercent,
                    title: '1月完成率',
                    dataIndex: 'completion_rate_month1',
                },
                {
                    render: renderMoney2W,
                    title: '2月目标',
                    dataIndex: 'income_target_month2',
                },
                {
                    render: renderMoney2W,
                    title: '2月业绩',
                    dataIndex: 'income_money_month2',
                },
                {
                    render: renderPercent,
                    title: '2月完成率',
                    dataIndex: 'completion_rate_month2',
                },
                {
                    render: renderMoney2W,
                    title: '3月目标',
                    dataIndex: 'income_target_month3',
                },
                {
                    render: renderMoney2W,
                    title: '3月业绩',
                    dataIndex: 'income_money_month3',
                },
                {
                    render: renderPercent,
                    title: '3月完成率',
                    dataIndex: 'completion_rate_month3',
                },
                {
                    render: renderMoney2W,
                    title: '4月目标',
                    dataIndex: 'income_target_month4',
                },
                {
                    render: renderMoney2W,
                    title: '4月业绩',
                    dataIndex: 'income_money_month4',
                },
                {
                    render: renderPercent,
                    title: '4月完成率',
                    dataIndex: 'completion_rate_month4',
                },
                {
                    render: renderMoney2W,
                    title: '5月目标',
                    dataIndex: 'income_target_month5',
                },
                {
                    render: renderMoney2W,
                    title: '5月业绩',
                    dataIndex: 'income_money_month5',
                },
                {
                    render: renderPercent,
                    title: '5月完成率',
                    dataIndex: 'completion_rate_month5',
                },
                {
                    render: renderMoney2W,
                    title: '6月目标',
                    dataIndex: 'income_target_month6',
                },
                {
                    render: renderMoney2W,
                    title: '6月业绩',
                    dataIndex: 'income_money_month6',
                },
                {
                    render: renderPercent,
                    title: '6月完成率',
                    dataIndex: 'completion_rate_month6',
                },
                {
                    render: renderMoney2W,
                    title: '7月目标',
                    dataIndex: 'income_target_month7',
                },
                {
                    render: renderMoney2W,
                    title: '7月业绩',
                    dataIndex: 'income_money_month7',
                },
                {
                    render: renderPercent,
                    title: '7月完成率',
                    dataIndex: 'completion_rate_month7',
                },
                {
                    render: renderMoney2W,
                    title: '8月目标',
                    dataIndex: 'income_target_month8',
                },
                {
                    render: renderMoney2W,
                    title: '8月业绩',
                    dataIndex: 'income_money_month8',
                },
                {
                    render: renderPercent,
                    title: '8月完成率',
                    dataIndex: 'completion_rate_month8',
                },
                {
                    render: renderMoney2W,
                    title: '9月目标',
                    dataIndex: 'income_target_month9',
                },
                {
                    render: renderMoney2W,
                    title: '9月业绩',
                    dataIndex: 'income_money_month9',
                },
                {
                    render: renderPercent,
                    title: '9月完成率',
                    dataIndex: 'completion_rate_month9',
                },
                {
                    render: renderMoney2W,
                    title: '10月目标',
                    dataIndex: 'income_target_month10',
                },
                {
                    render: renderMoney2W,
                    title: '10月业绩',
                    dataIndex: 'income_money_month10',
                },
                {
                    render: renderPercent,
                    title: '10月完成率',
                    dataIndex: 'completion_rate_month10',
                },
                {
                    render: renderMoney2W,
                    title: '11月目标',
                    dataIndex: 'income_target_month11',
                },
                {
                    render: renderMoney2W,
                    title: '11月业绩',
                    dataIndex: 'income_money_month11',
                },
                {
                    render: renderPercent,
                    title: '11月完成率',
                    dataIndex: 'completion_rate_month11',
                },
                {
                    render: renderMoney2W,
                    title: '12月目标',
                    dataIndex: 'income_target_month12',
                },
                {
                    render: renderMoney2W,
                    title: '12月业绩',
                    dataIndex: 'income_money_month12',
                },
                {
                    render: renderPercent,
                    title: '12月完成率',
                    dataIndex: 'completion_rate_month12',
                },
            ],
        },
    ];
    if (acMenuSelect) {
        switch (acMenuSelect) {
            case 'country':
                tableColumns.unshift({
                    title: '全年',
                    children: [
                        {
                            title: '目标',
                            render: renderMoney2W,
                            dataIndex: 'income_target_year',
                        },
                        {
                            title: '业绩',
                            render: renderMoney2W,
                            dataIndex: 'income_money_year',
                        },
                        {
                            title: '完成率',
                            render: renderPercent,
                            dataIndex: 'completion_rate_year',
                        },
                    ],
                });
                tableColumns.unshift({
                    title: '目标等级',
                    fixed: true,
                    dataIndex: 'target_level_name',
                });
                break;
            case 'personal':
                tableColumns.unshift({
                    title: '销售',
                    fixed: true,
                    dataIndex: 'sale_name',
                });
            case 'group':
                tableColumns.unshift({
                    title: '小组',
                    fixed: true,
                    dataIndex: 'sale_dept_name',
                });
            case 'city':
                tableColumns.unshift({
                    title: '城市',
                    fixed: true,
                    dataIndex: 'sale_city_name',
                });
            case 'area':
                tableColumns.unshift({
                    title: '区域',
                    fixed: true,
                    dataIndex: 'sale_area_name',
                });
            default:
                break;
        }
    }
    const fetchAchievementCompletionData = async () => {
        // const { year, menu } = filterData;
        if (!acYearSelect || !acMenuSelect) {
            // 若无，则代表没权限或权限未加载
            return;
        }
        const needFiledKey: string[] = [];
        _.forEach(tableColumns, item => {
            if (item.children) {
                _.forEach(item.children, cItem => {
                    console.log(cItem);
                    needFiledKey.push(cItem.dataIndex);
                });
            } else {
                needFiledKey.push(item.dataIndex);
            }
        });
        const url = '/crm/data/query/list_sale_income_oppty_target_model';
        setAchievementCompletionLoading(true);
        const [d, e] = await defaultAxios.put(url, {
            query_object_type_eq: menuKeyToDevType[acMenuSelect],
            query_target_year_eq: acYearSelect,
            fields: needFiledKey,
        });
        setAchievementCompletionLoading(false);
        if (d === null || d.data === null) {
            message.error('查询失败～');
            setAchievementCompletionData([]);
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data)) {
            setAchievementCompletionData(data);
        } else {
            setAchievementCompletionData([]);
            message.error(data?.msg || '查询失败～');
        }
    };
    const exportAchievementCompletionData = () => {
        const url = `/bff/api/rest/ana/sales/achievement-completion-export?object_type=${menuKeyToDevType[acMenuSelect]}&fiscal_year=${acYearSelect}&export_type=${acMenuSelect}`;
        window.open(url, '_blank');
    };
    return (
        <LayoutStandardCrud
            title={
                <TitleFilter
                    hiddenYear={false}
                    pageKey={'achievement-completion'}
                    onFilter={filterData => {
                        if (filterData) {
                            setACMenuSelect(filterData?.menu);
                            // fetchAchievementCompletionData(filterData);
                            setAcYearSelect(filterData.year);
                        }
                    }}
                    onExport={exportAchievementCompletionData}
                />
            }
            message={null}
            popups={null}
            multipleOps={null}
            globalOps={null}
            filters={null}
            dataTable={
                <TableLv2
                    dataSource={achievementCompletionData}
                    loading={achievementCompletionLoading}
                    closeRowSelection={true}
                    pagination={false}
                    scroll={{ x: '5000px' }}
                    columns={tableColumns}
                />
            }
        />
    );
};

export default observer(AchievementCompletion);
