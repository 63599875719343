import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutStandardCrud, AntButton } from '@/components';
import moment from 'moment';
import { defaultAxios } from '@/utils';
import { Form, Input, TreeSelect, Tooltip, Icon, Select } from 'antd';
import _ from 'lodash';
import { useRequest, useMount } from 'ahooks';
import { ColumnProps } from 'antd/lib/table';
import { StoreContext } from '@/stores';
import { MSelectStore } from '@/stores/utils';
import classname from 'classname';
import qs from 'qs';
import Table from './components/table';
import { CustomerFollowJobCreate } from '@/pages/sales-follow-up/job/jobs/lead/components/created-by-cus';
import { commonQuery } from '@/api/rest';
import UserSelector from '../../../../components/common/user-selector/index';

interface Params {
    orgIds?: (number | string)[];
    userIds?: (number | string)[];
    customerNameLike?: string;
    contractOverdue?: number | string;
}

const fetchData = async ({
    orgIds,
    userIds,
    customerNameLike,
    contractOverdue,
}: Params) => {
    const [d, err] = await defaultAxios.post(
        '/crm/equity/getTalentBankReport',
        { orgIds, userIds, customerNameLike, contractOverdue },
    );
    if (d === null || d.data === null || err) {
        return [];
    }
    const { data } = d;
    return data || [];
};

const exportRenewData = ({
    orgIds,
    userIds,
    customerNameLike,
    contractOverdue,
}: Params) => {
    const exportUrl = `/bff/api/rest/export/renew/cooperate-report?${qs.stringify(
        {
            orgIds: orgIds?.join(),
            userIds: userIds?.join(),
            customerNameLike,
            contractOverdue,
        },
    )}`;
    window.open(exportUrl, '_blank');
};

const fetchCustomers = async (cids: number[]) => {
    const [d, e] = await commonQuery(
        'customer',
        { pageSize: 200, pageNum: 1 },
        {
            filters: [
                {
                    fieldId: 'id',
                    operateType: 'in',
                    filterValue: cids.join(),
                    parser: 'string',
                    widgetKey: 'filterEnum',
                    extra: {
                        ts: 0,
                        isEmpty: false,
                    },
                },
            ],
        },
        undefined,
        'PRIVATE-VIEW',
        undefined,
        'SEARCH-PRIVATE',
    );
    if (d === null || d?.datas === null || e) {
        return null;
    }
    const data = d.datas;
    return data;
};

const ShowPhone: React.FC<{ mobile: string }> = ({ mobile }) => {
    const [show, setShow] = useState(false);

    return (
        <div style={{ whiteSpace: 'nowrap' }}>
            <span style={{ paddingRight: 10 }}>
                {show ? mobile : mobile?.slice(0, 3) + '********'}
            </span>
            <Icon
                onClick={() => {
                    setShow(!show);
                }}
                type="eye"
                style={{
                    fontSize: '12px',
                    color: '#0052ff',
                    marginRight: '2px',
                }}
            />
        </div>
    );
};

const defaultMSelect: MSelectStore = new MSelectStore();

const CooperateReport: React.FC = () => {
    const [isMount, setIsMount] = useState(false);
    const [orgIds, setOrgIds] = useState<(number | string)[]>();
    const [userIds, setUserIds] = useState<(number | string)[]>();
    const [customerNameLike, setCustomerNameLike] = useState<string>();
    const [contractOverdue, setContractOverdue] = useState<string | number>();
    const [selectedData, setSelectedData] = useState<any[]>([]);
    const [customer, setCustomer] = useState<any[]>([]);

    const [
        customerFollowJobCreateTrigger,
        setCustomerFollowJobCreateTrigger,
    ] = useState(0);

    const store = useContext(StoreContext);

    const settingStore = store.getSettingDefaultStore();
    const {
        departInfo,
        getDepartInfo,
        customerInfo,
        getCustomerInfo,
        isCustomerInfoLoading,
    } = settingStore; // 用户选择下拉框数据

    useEffect(() => {
        getDepartInfo();
        getCustomerInfo();
    }, []);

    useMount(() => {
        setIsMount(true);
    });

    // 续约列表数据
    const { data, loading } = useRequest(
        () => fetchData({ orgIds, userIds, customerNameLike, contractOverdue }),
        { refreshDeps: [orgIds, userIds, customerNameLike, contractOverdue] },
    );

    const handleCreateTask = async (record: any) => {
        const response = await fetchCustomers([record.customer_id]);
        setCustomer(response as any);
        setCustomerFollowJobCreateTrigger(1 + customerFollowJobCreateTrigger);
    };

    const tableColumns: ColumnProps<any>[] = [
        {
            title: '客户基础信息',
            children: [
                {
                    title: '销售',
                    dataIndex: 'bd_name',
                    width: '80px',
                },
                {
                    title: '合作客户',
                    dataIndex: 'co_full_name',
                    width: '160px',
                },
                {
                    title: '管理员姓名',
                    dataIndex: 'adm_name',
                    width: '80px',
                },
                {
                    title: '管理员手机号',
                    dataIndex: 'adm_mobile',
                    width: '120px',
                },
                {
                    title: '去年合作金额',
                    dataIndex: 'cooperate_amount_last_year',
                    width: '90px',
                },

                {
                    title: '今年合作金额',
                    dataIndex: 'cooperate_amount_this_year',
                    width: '90px',
                },
                {
                    title: '合作到期时间',
                    dataIndex: 'mbr_end_time',
                    width: '100px',
                    render: v => {
                        return v ? moment(v).format('YYYY-MM-DD') : null;
                    },
                },
                {
                    title: '最新活动记录时间&内容',
                    dataIndex: 'recent_activity_record_time',
                    width: '100px',
                    render: v => {
                        return v ? moment(v).format('YYYY-MM-DD') : null;
                    },
                },
                {
                    title: '任务截止时间',
                    dataIndex: 'task_end_time',
                    width: '100px',
                    render: v => {
                        if (!v) return null;
                        // 如果小于当前时间不显示
                        if (moment(v).isBefore(new Date())) return null;
                        return moment(v).format('YYYY-MM-DD');
                    },
                },
            ],
        },
        {
            title: '权益消耗信息',
            children: [
                {
                    title: '子账号总数',
                    dataIndex: 'license_nbr',
                    width: '80px',
                },
                {
                    title: '已分配账号数',
                    dataIndex: 'bind_lic_num',
                    width: '90px',
                },
                {
                    title: '电话总量',
                    dataIndex: 'reach_nbr_new_total',
                    width: '80px',
                },
                {
                    title: '电话使用量',
                    dataIndex: 'reach_nbr_new_used',
                    width: '80px',
                },
                {
                    title: '电话剩余',
                    dataIndex: 'reach_nbr_new_left',
                    width: '80px',
                },
            ],
        },
        {
            title: '需求判断信息',
            children: [
                {
                    title: '近30日在线职位总数',
                    dataIndex: 'valid_jobs_30d',
                    width: '90px',
                },
                {
                    title: '近7日新增职位总数',
                    dataIndex: 'release_jobs_7d',
                    width: '90px',
                },
            ],
        },
        {
            title: '活跃信息',
            children: [
                {
                    title: '近30天子账号最大活跃天数',
                    dataIndex: 'max_active_days',
                    width: '90px',
                },
                {
                    title: '近30天子账号最小活跃天数',
                    dataIndex: 'min_active_days',
                    width: '90px',
                },
            ],
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: '80px',
            render: (text, record) => {
                return <a onClick={() => handleCreateTask(record)}>新建任务</a>;
            },
        },
    ];

    const filters = (
        <Form layout="inline" style={{ margin: '5px 0' }}>
            <Form.Item label="组织">
                <TreeSelect
                    style={{ width: 200 }}
                    allowClear
                    treeCheckable
                    treeDataSimpleMode
                    treeNodeFilterProp="title"
                    placeholder="请选择部门"
                    treeData={departInfo}
                    treeDefaultExpandedKeys={['1']}
                    showCheckedStrategy={'SHOW_PARENT'}
                    onChange={(e: any) => {
                        setOrgIds(e);
                    }}
                />
            </Form.Item>
            <Form.Item label="销售">
                <UserSelector
                    style={{ width: 200 }}
                    name="user"
                    mode="multiple"
                    placeholder="请选择销售"
                    onChange={(e: any) => {
                        setUserIds(e);
                    }}
                    loading={isCustomerInfoLoading}
                    userDocs={customerInfo as any}
                />
            </Form.Item>
            <Form.Item label="客户名称">
                <Input.Search
                    allowClear
                    placeholder="客户名称"
                    onSearch={value => setCustomerNameLike(value)}
                    style={{ width: 200 }}
                />
            </Form.Item>
            <Form.Item label="是否到期">
                <Select<number>
                    allowClear
                    placeholder="请选择"
                    onChange={value => {
                        setContractOverdue(value);
                    }}
                    style={{ width: 80 }}
                >
                    <Select.Option value={1}>是</Select.Option>
                    <Select.Option value={0}>否</Select.Option>
                </Select>
            </Form.Item>
        </Form>
    );

    const multipleOps = (
        <div
            className={classname({
                'mselected-ops': true,
                'multiple-mode': defaultMSelect.isMSelectionMode,
                'normal-mode': !defaultMSelect.isMSelectionMode,
            })}
        >
            <Tooltip title="取消多选">
                <span
                    onClick={() => {
                        defaultMSelect.setMSelectedData([]);
                    }}
                    style={{
                        padding: 5,
                        cursor: 'pointer',
                    }}
                >
                    <Icon type="close" />
                </span>
            </Tooltip>
            <div>
                <span>已选{defaultMSelect.mSeletedData.length}条：</span>
            </div>
            {
                <AntButton
                    onClick={async () => {
                        const ids = selectedData?.map(item => item.customer_id);
                        const response = await fetchCustomers(ids);
                        setCustomer(response as any);

                        setCustomerFollowJobCreateTrigger(
                            1 + customerFollowJobCreateTrigger,
                        );
                    }}
                    size="large"
                >
                    新建任务
                </AntButton>
            }
        </div>
    );

    const globalOps = (
        <AntButton
            onClick={() => {
                exportRenewData({ orgIds, userIds, customerNameLike });
            }}
            size="large"
        >
            导出
        </AntButton>
    );

    const popups = (
        <CustomerFollowJobCreate
            customers={customer}
            trigger={customerFollowJobCreateTrigger}
            followLog={{ followTarget: 8 }}
            onSuccess={() => {
                setCustomerFollowJobCreateTrigger(0);
            }}
            onClose={() => {
                setCustomerFollowJobCreateTrigger(0);
            }}
        />
    );

    if (loading && !isMount) {
        return <></>;
    }

    return (
        <>
            <LayoutStandardCrud
                title={'合作客户数据盘点'}
                message={null}
                popups={popups}
                multipleOps={multipleOps}
                globalOps={globalOps}
                filters={filters}
                filtersDisplay={null}
                dataTable={
                    <Table
                        dataSource={data}
                        loading={loading}
                        columns={tableColumns}
                        pagination={{ defaultPageSize: 20 }}
                        rowKey={record => record.id}
                        rowSelection={{
                            selectedRowKeys: defaultMSelect.mSeletedData,
                            fixed: true,
                            onChange: (keys, row: any) => {
                                defaultMSelect.setMSelectedData(keys);
                                setSelectedData(row);
                            },
                        }}
                    />
                }
            />
        </>
    );
};

export default observer(CooperateReport);
