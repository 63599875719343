import { makeAutoObservable } from 'mobx';
import {
    createDefaultInstanceGetter,
    TPaginationStoreInstance,
    PaginationStore,
} from '@/stores/utils';
import { defaultAxios } from '@/utils';
import moment from 'moment';
import { IMemberListSearchData } from './index';
import { message } from 'antd';
import _ from 'lodash';
export interface IMemberListData {
    co_full_name: string | number; // 企业名称
    co_short_name: string | number; // 企业短称
    bd_name: string | number; // 销售
    co_identity: string | number; // 账号类型
    adm_name: string | number; // 管理员姓名
    adm_mobile: string | number; // 管理员手机
    mbr_end_time: string | number; // 有效期至
    license_nbr: string | number; // license额度
    mm_coin_balance_total: string | number; // 总点数
    mm_coin_balance_left: string | number; // 剩余点数
    batch_invite_balance_total: string | number; // 智能邀约-总数
    batch_invite_balance_left: string | number; // 智能邀约-剩余
    addfr_exchange: string | number; // 加好友-已兑换
    addfr_left: string | number; // 加好友-剩余
    addfr_used: string | number; // 加好友-已使用
    uh_exchange: string | number; // 极速联系-已兑换
    uh_left: string | number; // 极速联系-剩余
    uh_used: string | number; // 极速联系-已使用
    exposure_exchange: string | number; // 极速曝光-已兑换
    exposure_left: string | number; // 极速曝光-剩余
    exposure_used: string | number; // 极速曝光-已使用
    talent_lib_version: string | number; // 人才库版本
    reach_nbr: string | number; // 每日虚拟电话权益上限
    attention_company_nbr: string | number; // 关注公司数量
    direct_oppo_monthly: string | number; // 立即沟通（月配额）
    add_fr_monthly: string | number; // 加好友（月配额）
    super_exposure: string | number; // 极速曝光（月配额）
    [key: string]: any;
}
export interface IMemberChildData {
    ucard: {
        name: string; // 员工姓名
        mobile: string; // 手机号
        email: string; // 邮箱
        id: string; // uid
        join_time: string; // 开通时间
        expire_time: string; // 结束时间
    };
    report: {
        reach_nbr: number; // 每日虚拟电话权益上限
        id: number | string; // id
        license_serial: number;
        sub_uid: number;
        b_mbr_co_id: number;
        expire_time: string; // 过期时间
        // mock
        jobs_total: number | string; // 进行中的项目
    };
    cid: number | string;
}
export class MemberListStore {
    constructor() {
        makeAutoObservable(this);
    }
    public memberListData: IMemberListData[] = [];
    public memberListLoading: boolean = false;
    public defaultMemberListPagination: TPaginationStoreInstance = new PaginationStore();
    public clearStore = () => {
        this.memberListData = [];
        this.memberListLoading = false;
        this.defaultMemberListPagination = new PaginationStore();
        this.defaultMemberListPagination.pagesize = 20;
    };
    public getMemberListData = async (searchData: IMemberListSearchData) => {
        this.memberListLoading = true;
        const url = '/bff/api/rest/member-list/list';
        const [d, e] = await defaultAxios.post(
            url,
            {
                ...searchData,
                ...this.defaultMemberListPagination.paginationArgs,
            },
            {
                timeout: 400000,
            },
        );
        this.memberListLoading = false;
        if (d === null || d.data === null) {
            message.error('查询失败～');
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data.data)) {
            if (data.totalSize) {
                this.defaultMemberListPagination.amount = data.totalSize;
            }
            this.memberListData = data.data;
        } else {
            this.memberListData = [];
            message.error(data.msg || '查询失败～');
        }
        const error = e as Error | null;
        return [data, error];
    };
    public exportMemberListData = async (searchData: IMemberListSearchData) => {
        // if (this.channelGatherAnaData.length > 10000) {
        //     Modal.error({
        //         title: '导出提示',
        //         content: (
        //             <p>
        //                 最多支持导出10000条（当前条件共
        //                 {this.channelGatherAnaData.length}
        //                 条，请更换筛选条件再导出
        //             </p>
        //         ),
        //     });
        //     return;
        // }
        const url = `${window.location.origin}/bff/api/rest/member-list/export?start=${searchData.start}&end=${searchData.end}&cid=${searchData.cid}&mobile=${searchData.mobile}`;
        window.open(url, '_blank');
        return;
    };
    public closeTrail = async (id: string | number) => {
        const url = '/bff/api/rest/member-list/close-trail';
        const [d, e] = await defaultAxios.post(url, {
            id,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    public createEmptyAccount = async (
        selectedItem: IMemberListData,
        nbr: number,
    ) => {
        const url = '/bff/api/rest/member-list/create-blank';
        const [d, e] = await defaultAxios.post(url, {
            adm_uid: selectedItem.adm_uid,
            nbr,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };

    // 延期申请
    public dueDataSubmit = async (
        selectedItem: IMemberListData,
        duePayload: {
            dueType?: string | number;
            dueReason?: string;
            dueDate?: any;
        },
    ) => {
        const url = '/bff/api/rest/member-list/delay';
        const [d, e] = await defaultAxios.post(url, {
            target_time: duePayload.dueDate.format('YYYY-MM-DD'),
            ignore: 1,
            id: selectedItem.id,
            reason: duePayload.dueReason,
            product_type: '人才银行',
            extend_type: duePayload.dueType,
            adm_mobile: selectedItem.adm_mobile,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 修改权益
    public modifyEquality = async (
        selectedItem: IMemberListData,
        modifyPayload: {
            talentLibVersion: string | number;
            attentionCompanyNum: string | number;
            mmCoinBalance: string | number;
            batchInviteBalanceLeft: string | number;
            coIdentity: string | number;
            reachLimit: string | number;
            directOppo: string | number;
            directOppoMonthBalance: string | number;
            addFrMonthBalance: string | number;
            aiTicketNum: string | number;
            aiAccountNum: string | number;
        },
    ) => {
        const url = '/bff/api/rest/member-list/update-equity';
        const payload = {
            co_id: selectedItem.id,
            talent_lib_version: modifyPayload.talentLibVersion,
            attention_company_nbr: modifyPayload.attentionCompanyNum,
            mm_coin_balance: modifyPayload.mmCoinBalance,
            batch_invite_balance: modifyPayload.batchInviteBalanceLeft,
            co_identity: Number(modifyPayload.coIdentity),
            reach_nbr_new: modifyPayload.reachLimit,
            direct_oppo_balance: modifyPayload.directOppo,
            direct_oppo_monthly_balance: modifyPayload.directOppoMonthBalance,
            add_fr_monthly_balance: modifyPayload.addFrMonthBalance,
            ai_ticket_num: modifyPayload.aiTicketNum,
            ai_account_num: modifyPayload.aiAccountNum,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 修改cid
    public modifyCid = async (selectedItem: IMemberListData, cid: number) => {
        const url = '/crm/member/updateCid';
        const payload = {
            b_mbr_co_id: selectedItem.id,
            new_cid: cid,
            cid: selectedItem.cid,
            adm_mobile: selectedItem.adm_mobile,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 清除子账号权益
    public clearChildEquality = async (items: IMemberChildData[]) => {
        const array = items.map(v => ({
            license_id: v.report.id,
            license_serial: v.report.license_serial,
            sub_uid: v.report.sub_uid,
            co_id: v.report.b_mbr_co_id,
        }));
        const url = '/bff/api/rest/member-list/clear-child';
        const [d, e] = await defaultAxios.post(url, array);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 删除子账号
    public deleteChildEquality = async (items: IMemberChildData[]) => {
        const array = items.map(v => ({
            license_id: v.report.id,
            license_serial: v.report.license_serial,
            sub_uid: v.report.sub_uid,
            co_id: v.report.b_mbr_co_id,
        }));
        const url = '/bff/api/rest/member-list/delete-child';
        const [d, e] = await defaultAxios.post(url, array);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 回收子账号
    public recycleChild = async (items: IMemberChildData[]) => {
        const licId = items[0].report.id;
        const url = '/crm/member/recyclingSubAccount';
        const [d, e] = await defaultAxios.post(url, {
            to_license_id: licId,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 批量回收子账号
    public batchRecycleChild = async (items: IMemberChildData[]) => {
        const licIdArray = _.map(items, item => {
            return item.report.id;
        });
        const url = '/crm/member/recyclingSubAccountList';
        const [d, e] = await defaultAxios.post(url, licIdArray);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 修改电话权益
    public changeMobileEquality = async (
        items: IMemberChildData[],
        type: number,
        equityCount: number,
    ) => {
        const { b_mbr_co_id } = (items[0] && items[0].report) || {};
        const payload = {
            co_id: b_mbr_co_id,
            equity_count: equityCount,
            equity_type: type,
            licenses: items.map(v => ({
                license_id: v.report.id,
                license_serial: v.report.license_serial,
                sub_uid: v.report.sub_uid,
            })),
        };
        const url = '/bff/api/rest/member-list/update-license-equity';
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 分配子账号权益
    public distributeChildEquality = async (
        items: IMemberChildData[],
        equality: {
            addFrNum?: number;
            phoneCallNum?: number;
            reason?: string;
        },
    ) => {
        // tslint:disable-next-line:variable-name
        const license_id = items ? items[0].report.id : '';
        const payload = {
            new_reach_nbr: equality.phoneCallNum,
            new_add_fr_v2tov3: equality.addFrNum,
            op_reason: equality.reason,
            license_id,
        };
        const url = '/crm/member/memberLicenseDistributeEquity';
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
}
export const getDefaultMemberListStore = createDefaultInstanceGetter(
    MemberListStore,
);
