import { ListMeta } from '@/stores/utils';
import _ from 'lodash';
import { message } from 'antd';

export const talentBankVersion2ParamWhiteList = new Set([
    'talent_bank_version',
    'talent_bank_staff',

    'talent_bank_mc',
    'talent_bank_rbn',

    // 'talent_bank_bi',
    // 'talent_bank_ac',

    'product_talent_lib_version',
]);

export const talentBankVersion3ParamWhiteList = new Set([
    'talent_bank_version',
    'talent_bank_staff',

    'talent_bank_bn',
    'talent_bank_dom',
    'talent_bank_afm',

    // 'talent_bank_bi',
    'talent_bank_ac',

    'product_talent_lib_version',

    'workbench_direct_num', // 20210913
    // 'workbench_reach_num', // 20210913
]);

export const talentBankVersionWorkbenchParamWhiteArr = [
    'workbench_project_num',
    'workbench_reach_num',
    'workbench_direct_num',
    'workbench_reach_month_num',
    'workbench_subadmin_num',
    'workbench_deptment_num',
    'workbench_direct_month_num',
    'workbench_company_num',
    'workbench_recruit_num',
    'workbench_recruit_project_num',
    'workbench_friend_month_num',
    'product_talent_lib_version',
    'workbench_super_exposure',
    'workbench_mappings_and_dynamic_switch',
    'workbench_license_gold_medal_position',
    'workbench_old_ordinary_position',
    'workbench_admin_gold_medal_position',
    'workbench_jobs_limit',
    'workbench_ai_ticket_num',
    'workbench_ai_account_num',
];

export const talentBankVersionWorkbenchParamWhiteList = new Set([
    'talent_bank_version',
    ...talentBankVersionWorkbenchParamWhiteArr,
]);

export const openProductApplyChangeToMutatingData = (
    mutatingData: any,
    defaultMeta: ListMeta | null,
) => {
    // todo, 保留有用的数据
    if (mutatingData === null || defaultMeta === null) {
        return mutatingData;
    }

    const { defaultGroupReverseMap } = defaultMeta;
    const keys = _.keys(mutatingData);
    const { product_type } = mutatingData;
    if (product_type === 1) {
        const { talent_bank_version } = mutatingData;
        let whiteList = new Set<string>(['talent_bank_version']);
        if (talent_bank_version === 1) {
            // 人才银行
            // v2
            whiteList = talentBankVersion2ParamWhiteList;
        } else if (talent_bank_version === 0) {
            // 人才银行
            // v3
            whiteList = talentBankVersion3ParamWhiteList;
        } else if (talent_bank_version === 2) {
            // 人才银行
            // 工作台
            whiteList = talentBankVersionWorkbenchParamWhiteList;
        }

        // 保留版本中特有字段
        for (const k of keys) {
            if (defaultGroupReverseMap[k] === '32_bank') {
                if (whiteList.has(k)) {
                    continue;
                }
                mutatingData[k] = null;
            }
        }

        // 人才银行，移除企业号字段
        for (const k of keys) {
            if (defaultGroupReverseMap[k] === '32_enterprise') {
                mutatingData[k] = null;
            }
        }
    } else if (product_type === 0) {
        // 企业号，移除人才银行字段
        for (const k of keys) {
            if (defaultGroupReverseMap[k] === '32_bank') {
                mutatingData[k] = null;
            }
        }
    }

    return mutatingData;
};

export const changeAdminRequiredResult = (
    requiredParams: Set<string>,
    businessInfo: any,
) => {
    let result = true;
    const inValidKs: string[] = [];
    for (const k of Array.from(requiredParams)) {
        const v = businessInfo[k];
        if (v === null) {
            inValidKs.push(k);
            continue;
        }
        if (_.isString(v) && _.trim(v) === '') {
            inValidKs.push(k);
            continue;
        } else if (!_.isString(v) && !!v && !v?.originalValue) {
            inValidKs.push(k);
            continue;
        }
    }
    if (inValidKs.length > 0) {
        result = false;
        const msgs = [''];
        for (const k of inValidKs) {
            msgs.push(changeAdminParamsCnMap[k] + '必填');
        }
        msgs.shift();
        message.error(msgs.join('，'));
    }

    return {
        result,
        inValidKs,
    };
};

const changeAdminParamsCnMap: { [k: string]: string } = {
    admin_id: '管理员姓名',
    admin_phone: '管理员手机号',
    new_admin_id: '新管理员姓名',
    new_admin_phone: '新管理员手机号',
};

// 减少管理员，用这些字段
const removeAdminPhoneParams = [1, 2, 3, 4, 5].map(
    n => 'remove_admin_phone' + n,
);
export const removeAdminNameParams = [1, 2, 3, 4, 5].map(
    n => 'remove_admin_name' + n,
);
export const removeAdminParams = [
    ...removeAdminNameParams,
    ...removeAdminPhoneParams,
];

// 新增管理员，额外新增的字段
const newAdminPhoneParams = [2, 3, 4, 5].map(n => 'new_admin_phone' + n);
const newAdminidParams = [2, 3, 4, 5].map(n => 'new_admin_id' + n);
const newAdminParams = [...newAdminPhoneParams, ...newAdminidParams];

// 老字段，除了减少管理员，还是需要
const oldParams = [
    'admin_id',
    'admin_phone',
    'new_admin_id',
    'new_admin_phone',
];

export const allAdminParams = [
    ...oldParams,
    ...newAdminParams,
    ...removeAdminParams,
];

export const changeAdminGetRequiredAndHiddenParams = (
    product_type: any,
    change_type: any,
) => {
    let requiredParams = new Set<string>();
    let hiddenParams = new Set<string>();
    if (product_type === 1) {
        // 人才银行，变更(只有变更)
        requiredParams = new Set([...oldParams]);

        hiddenParams = new Set([...removeAdminParams, ...newAdminParams]);
    } else if (product_type === 2) {
        // 企业号
        if (change_type === 1) {
            // 新增
            requiredParams = new Set(['new_admin_id', 'new_admin_phone']);
            hiddenParams = new Set([
                'admin_id',
                'admin_phone',
                ...removeAdminParams,
            ]);
        } else if (change_type === 2) {
            // 变更
            requiredParams = new Set([...oldParams]);
            hiddenParams = new Set([...removeAdminParams, ...newAdminParams]);
        } else if (change_type === 3) {
            // 减少
            requiredParams = new Set([]);
            hiddenParams = new Set([...oldParams, ...newAdminParams]);
        } else {
            hiddenParams = new Set([
                ...oldParams,
                ...removeAdminParams,
                ...newAdminParams,
            ]);
        }
    } else {
        hiddenParams = new Set([
            ...oldParams,
            ...removeAdminParams,
            ...newAdminParams,
        ]);
    }
    return {
        requiredParams,
        hiddenParams,
    };
};

export const openProductChangeProductOpenTypeText = (
    metaItem: any,
    type: string,
) => {
    if (!metaItem.selectOptions) {
        return;
    }
    if (type === 'workbench') {
        metaItem.selectOptions.options = [
            {
                name: '开通新团队', // "新签",
                value: 1,
            },
            {
                name: '已有团队续约', // "续约",
                value: 2,
            },
            {
                name: '已有团队增购', // "增购",
                value: 3,
            },
        ];
        return;
    }
    metaItem.selectOptions.options = [
        {
            name: '新签',
            value: 1,
        },
        {
            name: '续约',
            value: 2,
        },
        {
            name: '增购',
            value: 3,
        },
    ];
};

export const getCusLocal = (cus: any) => {
    const city = cus?.city?.relatedObj?.city_name;
    const district = cus?.district?.relatedObj?.district_name;
    const state = cus?.state?.relatedObj?.state_name;

    const localArr = [state, city, district].filter(Boolean);
    if (localArr.length === 0) {
        return null;
    }
    return _.join(localArr, '-');
};
