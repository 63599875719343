import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { RouteComponentProps } from '@reach/router';
import { defaultAxios } from '@/utils';
import { IMemberChildData, IMemberListStagingChildData } from '../../store';
import { observer } from 'mobx-react-lite';
import { Button, Table, Popconfirm, message, Modal } from 'antd';
import { TableLv1 } from '@/components';
import _ from 'lodash';
import { StoreContext } from '@/stores';

export interface IExpandedMemberStagingRowProps {
    cid: string | number;
    authMap: {
        createBlank: boolean;
        delay: boolean;
        modifyEquality: boolean;
        switchTalent: boolean;
        retrieveSeat: boolean;
        enabledSeat: boolean;
        disabledSeat: boolean;
        exportMemberData: boolean;
        updateCid: boolean;
        distributeEquality: boolean;
    };
    shouldRefreshCid?: number;
    changeChildBindLogModalVis: (
        bool: boolean,
        selectedItem: IMemberListStagingChildData,
        selectedCid: number | string,
    ) => void;
    distributeChildEqualityModalVis: (
        bool: boolean,
        selectedItem: IMemberListStagingChildData,
        cid: any,
    ) => void;
}
const ExpandedMemberRow: React.FC<IExpandedMemberStagingRowProps &
    RouteComponentProps> = props => {
    const store = useContext(StoreContext);
    const memberListStagingStore = store.getDefaultMemberListStagingStore();
    const {
        retrieveSeat,
        switchSeatStatus,
        preRetrieveSeat,
    } = memberListStagingStore;
    const {
        cid,
        authMap,
        changeChildBindLogModalVis,
        distributeChildEqualityModalVis,
    } = props;
    const [fetchChildListLoading, setFetchChildListLoading] = useState(false);
    const [childListData, setChildListData] = useState<
        IMemberListStagingChildData[]
    >([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<
        string[] | number[]
    >();
    const [selectedRows, setSelectedRows] = useState<
        IMemberListStagingChildData[]
    >();
    const [stagingSeatPagination, setStagingSeatPagination] = useState<{
        current: number;
    }>({
        current: 0,
    });
    const [stagingSeatTotal, setStagingSeatTotal] = useState(1);
    useEffect(() => {
        if (props.shouldRefreshCid && props.cid === props.shouldRefreshCid) {
            fetchChildList();
        }
    }, [props.shouldRefreshCid]);
    const fetchChildList = async () => {
        setFetchChildListLoading(true);
        const url = '/bff/api/rest/member-list-staging/seat-list';
        const [d, e] = await defaultAxios.get(
            url,
            {
                co_id: cid,
                page: stagingSeatPagination.current,
                size: 100,
            },
            {
                timeout: 1000000,
            },
        );
        setFetchChildListLoading(false);
        if (d === null || d.data === null) {
            message.error('请求席位列表失败～');
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data?.data?.[0]?.lic_list)) {
            setChildListData(data?.data?.[0]?.lic_list);
            setStagingSeatTotal(data?.totalSize);
        }
    };
    useEffect(() => {
        if (cid) {
            fetchChildList();
        }
    }, [stagingSeatPagination.current]);
    const onSelectChange = (
        selectedRowKeys: string[] | number[],
        selectedRows: IMemberListStagingChildData[],
    ) => {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };
    const renderMemberChildTable = () => {
        return (
            <div className="child-table-wrapper">
                <Table<IMemberListStagingChildData>
                    loading={fetchChildListLoading}
                    dataSource={childListData}
                    pagination={{
                        pageSize: 100,
                        current: stagingSeatPagination.current + 1,
                        showSizeChanger: false,
                        total: stagingSeatTotal,
                        onChange: page => {
                            setStagingSeatPagination({
                                ...stagingSeatPagination,
                                current: page - 1,
                            });
                        },
                    }}
                    rowSelection={undefined}
                    // onSelectedDataChange={onSelectChange}
                    // rowSelection={{
                    //     selectedRowKeys,
                    //     onChange: onSelectChange,
                    // }}
                    size={'middle'}
                    columns={[
                        {
                            title: '序号',
                            key: 'index',
                            width: '50px',
                            render: (d, item, idx) => {
                                return <span>{idx + 1}</span>;
                            },
                        },
                        {
                            title: '员工姓名',
                            dataIndex: 'sub_u_name',
                            width: '100px',
                            render: d => {
                                if (d) {
                                    return d;
                                }
                                return '[未分配]';
                            },
                        },
                        {
                            title: '手机号',
                            dataIndex: 'mobile',
                            width: '120px',
                        },
                        {
                            title: 'uid',
                            dataIndex: 'sub_uid',
                            width: '120px',
                        },
                        {
                            title: '状态',
                            dataIndex: 'license_state',
                            width: '130px',
                            render: (d, v) => {
                                return d === 0 ? '禁用' : '启用';
                            },
                        },
                        {
                            title: '席位类型',
                            dataIndex: 'lic_type_name',
                            width: '120px',
                        },
                        {
                            title: '上次绑定',
                            dataIndex: 'last_bind',
                            width: '120px',
                            render: (v, item: IMemberListStagingChildData) => {
                                return v ? (
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            color: '#0052ff',
                                        }}
                                        onClick={() => {
                                            changeChildBindLogModalVis(
                                                true,
                                                item,
                                                cid,
                                            );
                                        }}
                                    >
                                        {v}
                                    </span>
                                ) : (
                                    '- -'
                                );
                            },
                        },
                        {
                            title: '电话沟通券剩余',
                            // dataIndex: 'reach_nbr',
                            width: '150px',
                            render: item => {
                                const canShow =
                                    _.includes(item.role_id, 2) ||
                                    _.includes(item.role_id, 3);
                                return canShow ? item.reach_nbr : '';
                            },
                        },
                        {
                            title: '不过期加好友券剩余',
                            width: '150px',
                            render: item => {
                                const canShow =
                                    _.includes(item.role_id, 2) ||
                                    _.includes(item.role_id, 3);
                                return canShow ? item.add_fr_v2tov3 : '';
                            },
                        },
                        {
                            title: '操作',
                            width: '200px',
                            key: 'op',
                            render: (item: IMemberListStagingChildData) => {
                                // 1: 管理员席位 2:子管理员席位  3: 招聘人员席位 4: 部门席位
                                const cantOp = _.includes(item.role_id, 1);
                                // TODO:
                                const canRetrieve =
                                    _.includes(item.role_id, 2) ||
                                    _.includes(item.role_id, 3) ||
                                    _.includes(item.role_id, 4);
                                if (cantOp) {
                                    return '- -';
                                }
                                const canDistribute =
                                    _.includes(item.role_id, 2) ||
                                    _.includes(item.role_id, 3);
                                const showRetrieve =
                                    item.license_state === 1 &&
                                    item.dist_state === 1;
                                const showDisabled =
                                    item.license_state === 1 &&
                                    item.dist_state === 0;
                                const showEnabled =
                                    item.license_state === 0 &&
                                    item.dist_state === 0;
                                return (
                                    <p className="member-child-op">
                                        {authMap.retrieveSeat &&
                                        canRetrieve &&
                                        showRetrieve ? (
                                            <span
                                                onClick={async () => {
                                                    const cnt = await preRetrieveSeat(
                                                        item,
                                                        cid,
                                                    );
                                                    Modal.confirm({
                                                        title:
                                                            '正在回收席位，请确认以下内容',
                                                        width: '450px',
                                                        content: (
                                                            <div className="member-staging-retrieve-modal">
                                                                <p>
                                                                    回收后「
                                                                    {item.sub_u_name ||
                                                                        '未分配'}
                                                                    」将从席位中解绑
                                                                </p>
                                                                {item.lic_type_name ===
                                                                '用人经理' ? (
                                                                    <p>
                                                                        作为成员参与的项目将保留在席位中
                                                                    </p>
                                                                ) : (
                                                                    <>
                                                                        <p>
                                                                            回收后立即沟通月配额、项目固定额度留存在席位，额外分配的权益（立即沟通券、电话沟通券、项目数）将回收至团队
                                                                        </p>
                                                                        <p>
                                                                            如果是子管理员，将回收至团队
                                                                        </p>
                                                                        <p>
                                                                            作为归属人和成员参与的项目将保留在席位中
                                                                        </p>
                                                                        <p>
                                                                            系统将自动关闭当前席位归属的进行中的项目，共
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        'red',
                                                                                    marginLeft:
                                                                                        '3px',
                                                                                    marginRight:
                                                                                        '3px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    cnt
                                                                                }
                                                                            </span>
                                                                            个
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ),
                                                        onOk: async () => {
                                                            const res = await retrieveSeat(
                                                                item,
                                                            );
                                                            if (res === true) {
                                                                message.success(
                                                                    '回收成功',
                                                                );
                                                                fetchChildList();
                                                                return true;
                                                            } else {
                                                                message.error(
                                                                    typeof res ===
                                                                        'string'
                                                                        ? res
                                                                        : '回收失败',
                                                                );
                                                                return false;
                                                            }
                                                        },
                                                    });
                                                }}
                                            >
                                                回收席位
                                            </span>
                                        ) : null}
                                        {authMap.disabledSeat &&
                                        showDisabled ? (
                                            <Popconfirm
                                                title={'确定要禁用席位？'}
                                                onConfirm={async () => {
                                                    const res = await switchSeatStatus(
                                                        [item],
                                                        false,
                                                    );
                                                    if (res === true) {
                                                        message.success(
                                                            '禁用席位成功',
                                                        );
                                                        fetchChildList();
                                                    } else {
                                                        message.error(
                                                            typeof res ===
                                                                'string'
                                                                ? res
                                                                : '禁用席位失败',
                                                        );
                                                    }
                                                }}
                                                okText="确认"
                                                cancelText="取消"
                                            >
                                                <span>禁用席位</span>
                                            </Popconfirm>
                                        ) : null}
                                        {authMap.enabledSeat && showEnabled ? (
                                            <Popconfirm
                                                title={`确定要启用席位？`}
                                                onConfirm={async () => {
                                                    const res = await switchSeatStatus(
                                                        [item],
                                                        true,
                                                    );
                                                    if (res === true) {
                                                        message.success(
                                                            '启用席位成功',
                                                        );
                                                        fetchChildList();
                                                    } else {
                                                        message.error(
                                                            typeof res ===
                                                                'string'
                                                                ? res
                                                                : '启用席位失败',
                                                        );
                                                    }
                                                }}
                                                okText="确认"
                                                cancelText="取消"
                                            >
                                                <span>启用席位</span>
                                            </Popconfirm>
                                        ) : null}
                                        {authMap.distributeEquality &&
                                        canDistribute ? (
                                            <span
                                                onClick={() => {
                                                    distributeChildEqualityModalVis(
                                                        true,
                                                        item,
                                                        cid,
                                                    );
                                                }}
                                            >
                                                分配权益
                                            </span>
                                        ) : null}
                                    </p>
                                );
                            },
                        },
                    ]}
                />
            </div>
        );
    };
    return (
        <>
            {/* <div className="child-batch-content">
                {authMap.disabledSeat ? (
                    <Button
                        size={'small'}
                        style={{ marginBottom: '15px' }}
                        onClick={async () => {
                            if (selectedRows && selectedRows.length > 0) {
                                const res = await switchSeatStatus(
                                    selectedRows,
                                    false,
                                );
                                if (res) {
                                    message.success('批量禁用席位成功');
                                } else {
                                    message.error('批量禁用席位失败');
                                }
                                fetchChildList();
                            } else {
                                message.warning('无选择的子账号');
                            }
                        }}
                    >
                        批量禁用席位
                    </Button>
                ) : null}
                {authMap.enabledSeat ? (
                    <Button
                        size={'small'}
                        style={{ marginBottom: '15px' }}
                        onClick={async () => {
                            if (selectedRows && selectedRows.length > 0) {
                                const res = await switchSeatStatus(
                                    selectedRows,
                                    true,
                                );
                                if (res) {
                                    message.success('批量启用席位成功');
                                } else {
                                    message.error('批量启用席位失败');
                                }
                                fetchChildList();
                            } else {
                                message.warning('无选择的子账号');
                            }
                        }}
                    >
                        批量启用席位
                    </Button>
                ) : null}
            </div> */}
            {renderMemberChildTable()}
        </>
    );
};

export default observer(ExpandedMemberRow);
