import React, { useEffect, useState } from 'react';
import {
    embedDataToWidgets,
    getAutoOnChangeHandler,
    FormLV2,
    IFormLV2Props,
} from '@/components';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ListMeta } from '@/stores/utils';
import {
    IWidgetItem,
    IItemOptionItem,
    CommonFormOnChange,
    CommonFormOnInteract,
} from '@/components/common/form/advanced-filters';
import { getParamPermApi } from '@/stores/perm';
import { arrToJson } from '@/utils';
import { BlockLoading2 } from '../block-loading';

export type TValidatorMap = { [key in keyof any]?: Validator<any> };

export interface IDFormStore<T = any> {
    // 对业务store的要求
    defaultMeta: ListMeta; // 要有一个metaData的store
    mutatingData: T; // 要有数据
    setMutatingData: (next: T) => void; // 更改数据的回调
    newDataHash?: string; // 数据hash
    netValidateResult?: ValidateResult<T>; // 外部数据校验结果
    clearParamValidateState: (key: string) => void; // 数据校验清理回调
    paramsToClearValidateState?: string[];
}

export interface IDFormConfig {
    // 一些配置项
    autoInit?: boolean;
    grouped?: boolean;
}

export interface IDFormProps<T = any, E = any> {
    defaultStore: IDFormStore; // 业务store
    options?: IDFormConfig; // 配置项
    propsTransformer?: (
        // 下面都是“作弊器”，可对默认行为篡改
        props: IFormLV2Props<T, E> & { key: string },
    ) => IFormLV2Props<T, E> & { key: string };
    widgetsGenerator?: (store: IDFormStore) => Array<IWidgetItem<T>>;
    widgetsTransformer?: (
        widgets: Array<IWidgetItem<T>>,
    ) => Array<IWidgetItem<T>>;
    labelsGenerator?: (store: IDFormStore) => Array<IItemOptionItem<T>>;
    labelsTransformer?: (
        labels: Array<IItemOptionItem<T>>,
    ) => Array<IItemOptionItem<T>>;
    validatorsGenerator?: (store: IDFormStore) => TValidatorMap;
    validatorsTransformer?: (validators: TValidatorMap) => TValidatorMap;
    onChange?: CommonFormOnChange<T>;
    onInteract?: CommonFormOnInteract<T>;
    eventCode?: string;

    // 工作流的上下文信息，封装工作流的权限
    processCtx?: IProcessCtx;

    /*
接口：GET http://192.168.1.20:9000/crm/workflow/instance/create?definitionId=myProcess:1:007c6656-9078-11eb-9b1f-160bce5524a5&currentUser=wangli
接口：GET http://192.168.1.20:9000/crm/workflow/instance/detail?instanceId=180cd530-912a-11eb-85ce-160bce5524a5&currentUser=wangli
     */
}

export interface IProcessCtx {
    definitionId?: string; //
    instanceId?: string;
    currentUser?: string;
}

const getProcessCtxHash = (processCtx?: IProcessCtx) => {
    if (!processCtx) {
        return 'None';
    }
    return [
        'definitionId:' + processCtx.definitionId,
        'instanceId:' + processCtx.instanceId,
        'currentUser:' + processCtx.currentUser,
    ].join(',');
};

export const defaultWidgetsGenerator: <T = any>(
    store: IDFormStore,
) => Array<IWidgetItem<T>> = store => {
    const { defaultMeta } = store;
    const wdigetsRaw = defaultMeta.defaultWidgetsAll;
    return wdigetsRaw as any;
};
export const defaultWidgetsTransformer: <T = any>(
    widgets: Array<IWidgetItem<T>>,
) => Array<IWidgetItem<T>> = widgets => widgets;
export const defaultLabelsGenerator: <T = any>(
    store: IDFormStore,
) => Array<IItemOptionItem<T>> = store => {
    const { defaultMeta } = store;
    const wdigetsRawLabels = defaultMeta.defaultWidgetLabels;
    return wdigetsRawLabels as any;
};
export const defaultLabelsTransformer: <T = any>(
    labels: Array<IItemOptionItem<T>>,
) => Array<IItemOptionItem<T>> = labels => labels;
export const defaultValidatorsGenerator: (
    store: IDFormStore,
) => TValidatorMap = store => {
    const { defaultMeta } = store;
    return defaultMeta.defaultValidators;
};
export const defaultValidatorsTransformer: (
    validators: TValidatorMap,
) => TValidatorMap = validators => validators;

export const DForm: React.FC<IDFormProps> = observer(
    ({
        defaultStore,
        options,
        propsTransformer,
        widgetsGenerator,
        widgetsTransformer,
        labelsGenerator,
        labelsTransformer,
        validatorsGenerator,
        validatorsTransformer,
        onChange: userOnChange,
        onInteract: userOnInteract,
        eventCode,
        processCtx,
    }) => {
        options = options || {};
        propsTransformer = propsTransformer || (props => props);
        widgetsGenerator = widgetsGenerator || defaultWidgetsGenerator;
        widgetsTransformer = widgetsTransformer || defaultWidgetsTransformer;
        labelsGenerator = labelsGenerator || defaultLabelsGenerator;
        labelsTransformer = labelsTransformer || defaultLabelsTransformer;
        validatorsGenerator = validatorsGenerator || defaultValidatorsGenerator;
        validatorsTransformer =
            validatorsTransformer || defaultValidatorsTransformer;

        const autoInit = _.isUndefined(options.autoInit)
            ? false
            : options.autoInit;
        const grouped = _.isUndefined(options.grouped)
            ? false
            : options.grouped;
        const {
            // 通用
            defaultMeta,
            mutatingData,
            newDataHash,
            netValidateResult,
        } = defaultStore;
        // mutatingData 一般存储我们需要的数据

        const { inited } = defaultMeta;
        useEffect(() => {
            if (!inited && autoInit) {
                defaultMeta.fetch();
            }
        }, [inited]);

        const [formParamsAll, setFormParamsAll] = useState<any[] | null>(null);
        const processCtxHash = getProcessCtxHash(processCtx);

        useEffect(() => {
            if (inited && eventCode) {
                (async () => {
                    const list = await defaultMeta.asyncFormParamsAll(
                        getParamPermApi(defaultMeta.tableId, eventCode),
                    );
                    setFormParamsAll(list);
                })();
            }
        }, [inited, eventCode]);

        useEffect(() => {
            if (inited && processCtx) {
                (async () => {
                    setFormParamsAll(null);
                    const list = await defaultMeta.processFormPerm(processCtx);
                    setFormParamsAll(list);
                })();
            }
        }, [inited, processCtxHash]);

        useEffect(() => {
            if (inited && !eventCode && !processCtx) {
                setFormParamsAll(defaultMeta.originalTableColsParamsAll);
            }
        }, [inited, eventCode, processCtxHash]);

        // return null

        // 两套权限体系，不可同时work
        if (eventCode && processCtx) {
            return <BlockLoading2 />;
        }

        if (formParamsAll === null && (eventCode || processCtx)) {
            return <BlockLoading2 />;
        }

        const defaultDataChangeHandler = getAutoOnChangeHandler(
            mutatingData,
            (data: any) => defaultStore.setMutatingData(data),
        );

        // 可以前插一段onChange
        let finalDataChangeHandler = defaultDataChangeHandler;
        if (!_.isUndefined(userOnChange)) {
            finalDataChangeHandler = (...args) => {
                userOnChange(...args);
                defaultDataChangeHandler(...args);
            };
        }

        let baiscWidgets = widgetsGenerator(defaultStore);
        const formParamsAllMap = arrToJson(formParamsAll || [], 'key');
        if ((eventCode || processCtx) && formParamsAll !== null) {
            // visible
            baiscWidgets = baiscWidgets.filter(
                (widget: any) => !!formParamsAllMap[widget.key],
            );
            // disable
            baiscWidgets.forEach(widget => {
                const key = widget.key as string;
                if (
                    formParamsAllMap[key] &&
                    formParamsAllMap[key].editable !== undefined
                ) {
                    widget.status = widget.status || {};
                    widget.status.disabled = !formParamsAllMap[key].editable;
                }
            });
        }

        // 优先级高所以后执行，覆盖上面的，但是有个问题是visible覆盖不了，历史问题，轻易不要乱动
        baiscWidgets = widgetsTransformer(baiscWidgets);

        const defaultValidators = validatorsGenerator(defaultStore);

        let basicformformItemOptions = labelsGenerator(defaultStore);
        let validators = defaultValidators;

        // 对于processCtx类型的，要将必填逻辑融入到元信息中
        // 20211013，在任务中，我们也需要这么做，但是这里先保守一点，把JOB类型的调用放出来
        if (processCtx || eventCode?.startsWith('JOB-')) {
            basicformformItemOptions = defaultMeta.genProcessWidgetLabelsByDefault(
                basicformformItemOptions,
                formParamsAllMap,
            );
            validators = defaultMeta.genProcessValidatorsByDefault(
                formParamsAllMap,
            );
        }
        validators = validatorsTransformer(validators);
        basicformformItemOptions = labelsTransformer(basicformformItemOptions);

        const layout = new Array(baiscWidgets.length).fill([24]);

        let props: IFormLV2Props<any, any> & { key: string } = {
            key:
                'id-' +
                ((mutatingData && mutatingData.id) || newDataHash || 'Null'),
            // 通过 data 使表单有默认值，即通过 mutatingData
            data: mutatingData,
            widgets: baiscWidgets,
            onChange: finalDataChangeHandler,
            formItemOptions: basicformformItemOptions,
            layout,
            onInteract: (key, type, value) => {
                if (!_.isUndefined(userOnInteract)) {
                    userOnInteract(key, type, value);
                }
                if (type === 'remove-validate-status') {
                    if (typeof key === 'string') {
                        // 这里可能是个坑！
                        defaultStore.clearParamValidateState(key);
                    }
                }
            },
            labelWidth: 15,
            groupReverseMap: defaultMeta.defaultGroupReverseMap,
            isAutoScrollFirstInvalidateItem: true,
            validators,
            validateMode: 'instantly',
            validateResult: netValidateResult,
            paramsToClearValidateState:
                defaultStore.paramsToClearValidateState || [],
        };
        if (grouped) {
            let defaultFormCollapseLayout =
                defaultMeta.defaultFormCollapseLayout;
            if ((eventCode || processCtx) && formParamsAll) {
                defaultFormCollapseLayout = defaultMeta.instantlyDefaultFormCollapseLayout(
                    formParamsAll,
                );
            }
            const collapseLayout = (props.collapseLayout = defaultFormCollapseLayout);
            props.collapseProps = {
                defaultActiveKey: collapseLayout.map(item => item.groupKey),
            };
        }

        props = propsTransformer(props);
        // 由 defaultStore 和 其他一堆东西 组成的 props 可以打印出来看看

        const form = <FormLV2<any> classNameLv2="dform" {...props}></FormLV2>;
        return form;
    },
);
